import { DefectTypeItem } from "@/features/ui/DefectTypeSet";
import { WorkEquipmentCell } from "@/features/workByManagement/WorkEquipmentCell";
import { WorkNumberInputCell } from "@/features/workByManagement/WorkNumberInputCell";
import { WorkProgressCell } from "@/features/workByManagement/WorkProgressCell";
import { WorkQuantityCell } from "@/features/workByManagement/WorkQuantityCell";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Flex, Text } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentWorksInner, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useWorkActions } from "./hooks/useWorkActions";

type PageKeyType = 'equipment' | 'plan';

type ItemType<T extends PageKeyType> = T extends 'equipment'
    ? AuthSignupPost201ResponseEquipmentWorksInner : ProductionPlansGet200ResponseRowsInnerWorksInner;

interface Params<T extends PageKeyType> {
    rowData: ItemType<T>;
    // onSave?: (rowData: ItemType<T>) => void;
    // setCheckedValues?: React.Dispatch<React.SetStateAction<string>>;
    pageKey: PageKeyType;
    socketCounter?: string | null;
    defectTypes: DefectTypeItem[] | null;
    bgColor?: string;
    formReset: boolean;
}

// 작업지시 Row의 작업 상태별 색상 정의
const workStatusColors = {
    WAITING: "",
    WORKING: theme.colors?.green?.[0],
    PAUSED: theme.colors?.orange?.[0],
    DONE: theme.colors?.gray?.[4]
}

// 작업지시 Row의 작업 상태 기본 색상 (작업지시가 없을 때)
const defaultWorkStatusColor = theme.colors?.gray?.[4];

export const WorkTableRow = <T extends PageKeyType>({ rowData, pageKey, defectTypes, bgColor, socketCounter, formReset }: Params<T>) => {
    const state = useWorkState();
    const actions = useWorkActions();
    const publish = usePub();
    const checkRef = useRef<HTMLInputElement>(null);
    const [checkedValues, setCheckedValues] = useState(false);
    const trackingStatus = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).trackingStatus;
    const lastWorkDate = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate ?
        dayjs((rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate).format('YYYY-MM-DD') : '';

    const [localSocketCounter, setLocalSocketCounter] = useState(socketCounter);

    useEffect(() => {
        setLocalSocketCounter(socketCounter);
        handleQuantityChange({ key: rowData.id?.toString() ?? "", value: socketCounter ?? rowData?.summary?.end ?? "0" });
    }, [socketCounter]);
    // 완료 수량 변경은 1차원 배열에 들어있어야 한다.
    // 예시 코드는 다음과 같다.
    // [{key: 123, value: 100}, {key: 456, value: 200}].... 의 형태
    // 여기서 key는 작업 id, value는 완료 수량이다.
    const handleQuantityChange = ({ key, value }: { key: string; value: string }) => {
        const data = state.tempQuantity.find((quantity) => quantity.key === (rowData.id && rowData?.id.toString()));
        if (data) {
            data.value = value;
        } else {
            state.tempQuantity.push({ key, value });
        }
        actions.setTempQuantity(state.tempQuantity);
    }

    // 불량 수량 변경은 2차원 배열에 들어있어야 한다.
    // 예시 코드는 다음과 같다.
    // [{id: 123, defect: [{key: 88, value:13}, {key:99, value:14}, ...]}, {id: 456, defect: [{key: 88, value:3}, {key:99, value:2}, ...]}].... 의 형태
    // 여기서 id는 작업 id, key는 불량 id, value는 불량 수량이다.
    const handleDefectChange = ({ key, value }: { key: string; value: string }) => {
        const workData = state.tempDefects.find((defect) => defect.id === (rowData.id && rowData?.id.toString()));
        const data = workData?.defect.find((defect) => defect.key === key);
        if (data) {
            data.value = value;
        } else {
            workData ? workData.defect.push({ key, value }) : state.tempDefects.push({ id: (rowData.id ? rowData.id.toString() : undefined), defect: [{ key, value }] });
        }
        actions.setTempDefects(state.tempDefects);
    }

    useSub('checkAll', () => {
        setCheckedValues(true);
    });

    useSub('unCheckAll', () => {
        setCheckedValues(false);
    });

    return (<WorkTr color={rowData.id && trackingStatus ? workStatusColors[trackingStatus] : defaultWorkStatusColor}>
        <Td minWidth={3} width={4}>
            <Flex align="center" justify="center">
                <Checkbox
                    ref={checkRef}
                    checked={checkedValues}
                    onChange={(e) => {
                        if (e.target.checked) {
                            publish('check');
                            setCheckedValues(true);
                            actions.setWorks((prevWorks: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[]) => [...prevWorks, rowData]);
                        } else {
                            publish('unCheck');
                            setCheckedValues(false);
                            actions.setWorks((prevWorks: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[]) => prevWorks.filter(work => work?.id !== rowData?.id));
                        }
                    }} />
            </Flex>
        </Td>
        {
            pageKey === 'plan' ? (
                <Td minWidth={5} width={8}><Text ta="right">{rowData.scheduleSeq}</Text></Td>
            ) : null
        }
        {
            pageKey === 'plan' ? (
                <Td minWidth={12} width={20}><Text>{lastWorkDate}</Text></Td>
            ) : null
        }
        <Td minWidth={12} width={20}>
            <WorkEquipmentCell data={{ equipmentCode: rowData.equipmentCode, equipmentName: rowData.equipmentName }} />
        </Td>
        <Td minWidth={22} width={31}>
            <WorkProgressCell
                data={{
                    itemName: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.name as string, //rowData?.item?.name,
                    itemCode: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.code as string, //rowData?.item?.code,
                    itemId: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.id as number, // rowData?.item?.id,
                    itemSpec: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.name as string, //rowData?.item?.spec,
                    workId: rowData.id,
                    operationCode: rowData.operationCode,
                    percent: rowData.summary?.percent ?? "0",
                    trackingStatus: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.trackingStatus, //rowData.trackingStatus,
                    purchaseOrderItem: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.purchaseOrderItem, //rowData?.purchaseOrderItem,
                }}
            />
        </Td>
        <Td minWidth={18} width={31}>
            <WorkQuantityCell
                data={{
                    targetQuantity: rowData.targetQuantity,
                    totalQuantity: rowData.summary?.totalQuantity,
                    todoQuantity: rowData.summary?.todoQuantity,
                    defectTotal: rowData.summary?.defectTotal,
                    unitText: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.unitText as string, //rowData?.item?.unitText,
                }}
            />
        </Td>
        <Td minWidth={8} width={14}>
            {
                rowData?.id ? (
                    <WorkNumberInputCell
                        data={{
                            key: rowData.id.toString(),
                            defaultValue: rowData?.summary?.end ?? "0",
                            value: localSocketCounter !== undefined && localSocketCounter !== null ? localSocketCounter : (
                                state.tempQuantity.find(data => data.key === rowData.id?.toString())?.value ??
                                rowData?.summary?.end ??
                                "0"
                            ),
                            onChange: handleQuantityChange,
                            formReset: formReset ?? false,
                        }}
                    />
                ) : null
            }
        </Td>
        {
            defectTypes?.map((defectType: DefectTypeItem) => (
                <Td key={defectType.value} minWidth={8} width={14}>{
                    rowData?.id ? (
                        <WorkNumberInputCell
                            data={{
                                key: defectType.value,
                                defaultValue: (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "0",
                                value: (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "",
                                onChange: handleDefectChange,
                                formReset: formReset ?? false,
                            }}
                        />
                    ) : null
                }</Td>
            ))
        }
    </WorkTr>)
}

export const Td = styled.td<{ minWidth?: number; width?: number; isNumber?: boolean }>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;

// WorkRow 영역
const WorkTr = styled.tr`
    td {
       background-color: ${(props) => props.color};
    }
`;

// Line clamp 스타일 생성
export const TextEllipsis = styled<any>(Text)`
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;