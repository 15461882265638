import { useQuery } from "react-query";

import DefaultInstance from "@/instance/axios";
import { WorksGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";

export const WORKS_GET_KEY = "/works";
const fetchWorksWorkGet = (
  defectCodes: string[],
  activePage: number,
  scheduledAt: any
) => {
  const query: any = {};
  if (scheduledAt) {
    // scheduledAt: dayjs(new Date(state.date)).format("YYYY-MM-DD"),

    const [beforeDate, afterDate] = scheduledAt;

    const before = dayjs(
      dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
    ).toISOString();

    const after = afterDate
      ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
      : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

    const date = after ? [before, after] : [before, before];
    query["query"] = JSON.stringify({
      $and: [
        {
          lastWorkDate: {
            $between: date,
          }
          // scheduledAt: {
          //   $between: date,
          // },
        },
      ],
    });
  }

  return DefaultInstance.worksGet(
    {
      ...query,
      page: activePage ?? 1,
      pageSize: 10,
    },
    {
      params: {
        defectCodes: defectCodes.map((data: any) => data.code) ?? ["null"],
        populate: [
          "summary",
          "item",
          "equipment",
          "productionPlan",
          "operationData",
          "purchaseOrderItem"
        ],
        sort: "-createdAt",
      },
    }
  );
};
export const useWorksGet = (
  {
    defectCodes = [],
    activePage,
    scheduledAt,
  }: { defectCodes: string[]; activePage: number; scheduledAt: Date },
  options?: {
    onSuccess: (work: WorksGet200Response) => void;
  }
) => {
  return useQuery(
    [WORKS_GET_KEY, defectCodes, activePage],
    () => fetchWorksWorkGet(defectCodes, activePage, scheduledAt),
    {
      select: (data: AxiosResponse<WorksGet200Response, any>) =>
        data.data as WorksGet200Response,
      onSuccess: options?.onSuccess,
    }
  );
};
