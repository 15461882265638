import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import { Checkbox, Flex, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { DefectTypeItem } from "../ui/DefectTypeSet";

type PageKeyType = 'equipment' | 'plan';

interface WorkTableHeadProps {
    defectTypes: DefectTypeItem[] | null;
    pageKey: PageKeyType;
}

export const WorkTableHead = ({ defectTypes, pageKey }: WorkTableHeadProps) => {
    const state = useWorkState();
    const actions = useWorkActions();
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const publish = usePub();

    // 상태 변경 시 동기화
    useEffect(() => {
        setAllChecked(state.works.length === state.tempWorks.length);
    }, [state.works, state.tempWorks]);

    useSub('check', () => {
        setAllChecked(state.works.length === state.tempWorks.length);
    });

    useSub('unCheck', () => {
        setAllChecked(false);
    });

    return (
        <thead>
            <tr>
                <th>
                    <Checkbox
                        checked={allChecked}
                        indeterminate={indeterminate}
                        onClick={(e) => {
                            if (e.currentTarget.checked) {
                                publish('checkAll');
                                actions.setWorks((prevWorks) => state.tempWorks);
                                setAllChecked(true);
                            }
                            else {
                                publish('unCheckAll');
                                actions.setWorks((prevWorks) => []);
                                setAllChecked(false);
                            }
                        }}
                    />
                </th>
                {
                    pageKey === 'plan' ? (
                        <th>
                            <Text fz="sm" fw={500} c="black" ta="center" span>
                                작업순서
                            </Text>
                        </th>
                    ) : null
                }
                {
                    pageKey === 'plan' ? (
                        <th>
                            <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
                                <Text fz="sm" fw={500} c="black" ta="center" span>
                                    최근 작업일
                                </Text>
                            </Flex>
                        </th>
                    ) : null
                }
                <th>
                    <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
                        <Text fz="sm" fw={500} c="black" ta="center">
                            라인명
                        </Text>
                        <Text fz="sm" fw={500} c="black" ta="center">
                            설비명
                        </Text>
                    </Flex>
                </th>
                <th>
                    <Flex align="center" direction="column" wrap="wrap">
                        <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
                            <Text fz="sm" fw={500} c="black">
                                AI 예측 품목(공정코드)
                            </Text>
                            <Text fz="sm" fw={500} c="black">
                                품목명(품목코드)
                            </Text>
                            <Text fz="sm" fw={500} c="black" ta="center">
                                규격
                            </Text>
                            <Text fz="sm" fw={500} c="black" ta="center">
                                진행률
                            </Text>
                        </Flex>
                    </Flex>
                </th>
                <th>
                    <Flex align="center" direction="column" wrap="wrap">
                        <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
                            <Flex gap="xs">
                                <Text fz="sm" fw={500} c={theme.colors?.blue?.[9]}>
                                    계획수량
                                </Text>
                                <Text>/</Text>
                                <Text fz="sm" fw={500} c={theme.colors?.orange?.[9]}>
                                    생산 잔량
                                </Text>
                            </Flex>
                            <Flex gap="xs">
                                <Text fz="sm" fw={500} c={theme.colors?.teal?.[9]} ta="center">
                                    총생산수량
                                </Text>
                                <Text>/</Text>
                                <Text fz="sm" fw={500} c={theme.colors?.red?.[9]} ta="center">
                                    불량수량
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </th>
                <th>
                    <Text fz="sm" fw={500} c="black" ta="center">
                        완료수량
                    </Text>
                </th>
                {
                    defectTypes?.map((defect) => (
                        <th key={defect.value}>
                            <Text fz="sm" fw={500} c="black" ta="center">
                                {defect.label}
                            </Text>
                        </th>
                    )
                    )
                }
            </tr>
        </thead>
    )
}